@import "../../globalStyles/colors";

.syncButton {
  color: $dark;
  margin-right: 10px;
}

.modalContent {
  text-align: center;
  padding: 40px;
}

.modalText {
  margin-top: 30px;
  font-size: 18px;
}

.arhivationWrap {
  display: flex;
  flex-direction: column;
}

.button {
  margin: 5px 0;
}

.optionsMenuWrap {
  display: flex;
  flex-direction: column;
}

.optionsMenuButton {
	width: 100%;
  margin: 5px 0;
}

.icon, .addIcon {
  position: absolute;
  top: 9px;
  left:20px;
}

.addIcon {
  left:8px;
}

.moveControl {
  margin: 5px auto;
}

.controlButton {
  margin: 0 5px;
}
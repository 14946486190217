@import "../../globalStyles/colors";

.menu {
  width: auto
}

.divider {
  margin: 0;
  background-color: $darkslategray;
}

.title {
  border-top: 3px solid $white;
  margin-top: 71px;
  display: flex;
  justify-content: space-between;
  line-height: 50px;
  height: 50px;
  color: $white;
  background-color: $darkslategray;
}

.titleName {
  padding-left: 24px;
  color: $white;
  & > h4 {
    color: $white;
  }
}

.titleImg {
  padding-right: 24px;
}

.menuItem:hover {
  background-color: $darkslategray!important;
  color:$dark!important;
}

.link {
  color: $darkslategray;
}

.linkArchived {
	color: $darkslategray;
	opacity: 0.4;
}
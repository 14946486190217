@import "../../../common/globalStyles/colors.scss";

.window {
  position: relative;
  min-height: 67vh;
  border-radius: 3px;
  border: 1px solid $darkslategray;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.viewButton {
  position: absolute;
  right: 8px;
  top: 8px;
}

@import '../../../common/globalStyles/colors.scss';

.wrap {
	padding: 20px 10px 0 10px;
	width: 100%;
	margin: 0 auto 20px auto;
	background-color: $white;
	box-shadow: $boxShadow;
}

.selectsWrap {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.button {
	margin: 0 5px 12px 10px;
}

.date {
	width: 100%;
	margin: 0 0 32px 0;
}

.dateTitle {
	margin: 0 0 8px 5px;
}

.dateFieldsWrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.dateField {
	width: 50%;
	margin: 0 5px;
}
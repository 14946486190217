.left-slider-menu {
  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }
}


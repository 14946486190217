.wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.wrapBottomButtons {
	display: flex;
	justify-content: space-between;
}

.fieldsWrap {
	width: 60%;
}

.saveButton {
	margin: 20px 10px;
}

.imgWrap {
  display: flex;
	justify-content: flex-start;
	margin-top: 10px;
}

.loadImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 170px; 
}

.title {
	text-align: center;
	margin-right: 8px;
}
@import "../../../common/globalStyles/colors.scss";

.category{
  padding: 2px;
  margin: 3px;
  background-color: $darkgray-two;
}

.titleWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 5px;
  margin: 0;
  background-color: $darkslategray;
  color: $white;
  border-radius: 2px; 
}

.title {
  font-size: 1.1em;
  color: $white;
}
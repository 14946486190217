@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';
.table {
	.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
		padding: 0;
	}
	.ant-table-thead > tr:first-child > th:first-child {
		padding: 20px;
	}
}
.wrap {
	margin: 0px auto;
	user-select: none;
	padding: 0 10px 0 10px;
	background-color: $white;
	box-shadow: $boxShadow;
}

.dishStatistic,
.additionalDishStatistic {
	display: flex;
	justify-content: space-between;
	height: max-content;
	min-height: 25px;
	line-height: 1.5;
	padding: 0px 10px;

	div:nth-child(1){
		width: 25%;
	}
	div:nth-child(2){
		width: 50%;
		text-transform: lowercase;
		font-weight: bold;
		span[role="img"]{
			margin: 0 10px 0 0;
		}
	}
	div:nth-child(2):first-letter {
		text-transform:capitalize;
	}
	div:nth-child(3),
	div:nth-child(4){
		width: 10%;
		text-align: center;
	}
	
}
.additionalDishStatistic{
	div:nth-child(2){
		font-weight: normal;
	}
}

.dishCount {
	margin-right: 20px;
}
.button {
	margin: 10px;
}

// :global(.ant-table-pagination.ant-pagination).tablePagination{
// 	margin-top: -185px;
// }
@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';

.wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
	height: 70vh;
	user-select: none;
}

.logo {
  margin: 0 auto;
  display: block;
  width: 100px;
	height: 100px;
	user-select: none;
}

.heading {
  width: fit-content;
  margin: 0 auto;
  font-size: 10em;
  font-family: 'Nova Slim', cursive;
	border-top: 1px solid $darkslategray;
	user-select: none;
}
@import '../../../common/globalStyles/colors.scss';

.wrapTopField {
  display: flex;
  padding: 0 0 20px 0;
}

.wrapWithoutImg {
  width: 100%;
}

.option {
  margin: 10px auto;
  width: 40%;
}

.categoryTableName {
  margin-top: 30px;
  text-align: center;
  & > h2 {
    color: $darkslategray-two;
  }
}

.wrapLangMenu{
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.langMenu {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.loadImg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 170px; 
}

.buttonWrap {
	display: flex;
	align-items: center;
}

.button {
	margin-right: 10px;
}
.synchronize-modal {
  .ant-spin-dot, .ant-spin-dot-spin{
    width: 50px;
    height: 50px;
  }

  .ant-spin-dot-item{
    width: 20px;
    height: 20px;
  }
}

.pageWrapper {
	min-height: calc(100vh - 300px);
	height: fit-content;
}

.wrap {
	margin: 0 auto;
	width: 98%;
	:global(.ant-spin){
		display: block;
		margin: 20px auto;
	}
}
@import "../../../common/globalStyles/colors.scss";

.wrap{
  padding-bottom: 6px;
  margin: 10px;
}

.icon {
	margin: 0 10px 0 5px;
}

.checkDish {
	font-size: 1.1em;
  height: 55.1px;
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2px 10px;
	background-color: $darkgray-two;
	box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
}

.buttonWrap {
  display: flex;
  justify-content: space-between;
}

.button {
  margin-left: 5px;
}


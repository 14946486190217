@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';


.wrap {
	user-select: none;
	padding: 10px;
	width: 100%;
	display: flex;
	.wrapper{
		box-shadow: $boxShadow;
		background-color: $white;
        margin: 0 15px 15px 0;
        border-radius: 5px;
		border-left: 5px solid;
		width: 18.66666667%;

		:global(.ant-card-body){
			padding: 10px;
			:global(.ant-statistic){
				display: flex;
				flex-direction: column-reverse;				
			}
		}

		&.ordersSum{
			border-left-color:#14a9ad;
			color: #14a9ad;
			:global(.ant-statistic-content){
				color: #14a9ad;
			}
		}
		&.avgReceipt{
			border-left-color: #63b598;
			color: #63b598;
			:global(.ant-statistic-content){
				color: #63b598;
			}
		}
		&.ordersCount{
			border-left-color:#ce7d78;
			color: #ce7d78;
			:global(.ant-statistic-content){
				color: #ce7d78;
			}
		}
		&.menuItmesCount{
			border-left-color: #ea9e70;
			color:  #ea9e70;
			:global(.ant-statistic-content){
				color: #ea9e70;
			}
		}
		&.additionalsCount{
			border-left-color: #a48a9e;
			color:  #a48a9e;
			:global(.ant-statistic-content){
				color: #a48a9e;
			}
		}
		&.avgMenuItemsPerOrder{
			border-left-color: #648177;
			color:  #648177;
			:global(.ant-statistic-content){
				color: #648177;
			}
		}
	}	
}
@import url('https://fonts.googleapis.com/css?family=Nova+Slim&display=swap');
@import '../../../common/globalStyles/colors.scss';

.wrap {
	margin: 0px auto;
	user-select: none;
	padding: 20px 10px 10px 10px;
	background-color: $white;
	box-shadow: $boxShadow;
}

.ratingWrap {
	margin: 0 0 10px 20px;
}
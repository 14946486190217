.categoryInputText > div {
  padding: 12px 30px;
}

.categoryDescription {
  margin-top: 0px;
}

.wrapTopField {
  display: flex;
}

.wrapWithoutImg {
  width: 100%;
}

.option {
  margin: 24px 0;
  width: 40%;
}

.wrapLangMenu{
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.langMenu {
  display: flex;
  justify-content: space-between;
  align-items: center
}
@import '../../globalStyles/colors.scss';

.header {
    background: $darkslategray;
    padding: 0;
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 64px;
}

.menu {
    background: $darkslategray;
    line-height: 62px;
    color: $white;
    border-bottom: 0;
}

.linkName {
    color: $white;
}

.rightMenu {
    margin-right: 20px;
}

.trobleButton,
.syncButton {
    color: $darkish-pink;
    padding: 0 5px;
}

.refreshButton {
    color: $lught-blue;
    margin: 2px 10px 0 0;
    background: $darkslategray;
    &:hover {
        background: $darkslategray;
        color: $blue-6;
    }
}

.popoverButton {
    width: 100%;
    margin: 5px 0;
}

.modalContent {
    text-align: center;
    padding: 40px;
}

.modalText {
    margin-top: 30px;
    font-size: 18px;
}

.userAvatar {
    margin-right: 10px;
    &:hover {
        cursor: pointer;
        border: 0.5px solid $white;
    }
}

.locationSelectWrap {
    padding-top: 12px;
    width: 350px;
    height: 64px;
}

.infoWrap {
    border: 1px solid $white;
    height: 38px;
    padding: 5px;
    margin-right: 10px;
}

.info {
    color: $white;
    font-size: 0.8em;
    line-height: 10px;
    height: 14px;
}

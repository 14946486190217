.imgWrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 50px;
    border-top: 1px solid #DDDDDD;
}

.loadImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 170px; 
}

.title {
    text-align: center;
    margin-right: 8px;
}
.menu-drawer {
  z-index: 50;

  .ant-drawer-content-wrapper {
    width: 360px !important;
    height: 100vh
  }
  .ant-drawer-close {
    display: none;
  }

  .ant-drawer-body {
    padding: 0;
  }
}

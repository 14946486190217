@import '../../../common/globalStyles/colors.scss';

.wrap {
    padding: 20px 10px 0 10px;
    width: 100%;
    margin: 0 auto 20px auto;
    background-color: $white;
    box-shadow: $boxShadow;
}

.selectsWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    margin: 0 5px 12px 10px;
}

.imgWrap {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.loadImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 170px;
}

.title {
    text-align: center;
    margin-right: 8px;
}


.errorCredentials {
  color: red;
  padding-bottom: 20px
}

.errorCredentialsIcon {
  margin-right: 10px;
  color: red;
  vertical-align: -0.2em;
}

.button {
  padding: 0 20px;
}

.arhivationWrap {
  display: flex;
  flex-direction: column;
}

.optionsButton {
	padding: 0 5px;
}

.optionsMenuWrap {
  display: flex;
  flex-direction: column;
}

.optionsMenuButton {
	width: 100%;
  margin: 5px 0;
}

.icon {
  position: absolute;
  top: 9px;
  left:40px;
}
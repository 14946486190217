.wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.wrapBottomButtons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fieldsWrap {
	width: 60%;
	.colorLabel{
		display: block;
		margin-top: -55px;
		text-align: center;
	}
	.wrapper{
		height: fit-content;
		.inputWrapper{
			display: flex;
		}
	}
	:global(.ant-row.ant-form-item){
		width: 33%;
	}
	:global(.ant-input-affix-wrapper .ant-input:not(:last-child)){
		padding-right: none;
	}
}

.saveButton {
	margin: 20px 10px 20px 5px;
}
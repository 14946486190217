@import "../../globalStyles/colors";

.button {
  background-color: $darkgray;
  color: $white;
  border-radius: 3px;
  text-align: center;
  line-height: 10px;
  height: 15px;
  width: 24px;
  cursor: pointer;
  &:hover {
    background-color: $lught-blue;
  }
}
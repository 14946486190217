

$navy-blue: #012a48;
$blue: #005a81;
$blue-6: #57a4e7;
$lught-blue: #2196f3;
$lightslategray: #e4e6e7;
$dimgray: #575c63;
$dimgray-two:#6c5b63;
$dark-navy-blue: #000019;
$darkgray: #9b9b91;
$darkgray-two: #bfc2bb;
$darkslategray:#464742;
$darkslategray-two:#868788;
$new-black: #1f2122;
$dark: #000;
$dark-one: rgba(0, 0, 0, 0.149);
$dark-two: rgba(0, 0, 0, 0.45);
$gainsboro: #d6dde8;
$whitesmoke: #f7f8fa;
$white-one: #fafafa;
$white-three: #f0f0f0;
$white-four: #d8d8d8;
$white-six: #dadada;
$white-seven: #C4C4C4;
$darkish-pink: #FF326F;
$brownish-grey: #656565;
$greyish: #aaaaaa;
$white: #ffffff;
$red: red;
$dark-red: #c10202;

$boxShadow: rgba(0, 0, 0, 0.20) 2px 2px 9px;

@import "../../../common/globalStyles/colors.scss";

.statisticNameInfo {
  color: $darkish-pink;
}

.statistic {
  width: 98%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0 4px 0;
  margin: 0 auto;
}
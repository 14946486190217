.chartsWrapper{
    display:flex;
    flex-direction:column;
    flex-wrap: wrap;
    margin: 50px 0 20px 0;
    h2{
        padding: 10px 0;
        text-align: center;
        border-bottom: 1px solid #DDDDDD;
    }   
    .oneLineWithOne{
        // margin: 50px 0 20px 0;
        .chartWrapper{
            width: 100%;
        }
    }
    .oneLineWithTwo{  
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        .chartWrapper{
            flex: 49%;
            width: 49%;
            margin: 0 1% 1% 0;
        }
    }
    .oneLineWithThree{
        // margin: 50px 0 20px 0;
        .oneLineWithThreeSub{
            display:flex;
            flex-direction:row;
            justify-content: space-between;
            flex-wrap: wrap;
            .chartWrapper{
                width: 32%;
                flex: 32%;
                margin: 0 1% 1% 0;
            }
        }
    }
    .chartWrapper{
        box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
        padding: 15px;
        border-radius: 5px;
        background: white;
        margin: 10px 0;
    }
    .chartsContainer{
        display:flex;
        flex-direction:row;
        flex-wrap: wrap;
        justify-content: center;
        >section{
            padding: 0px 10px;
            box-sizing: border-box;            
           
            :global(.rv-radial-chart){
                margin: 10px auto;
            }
            :global(.rv-discrete-color-legend){
                text-align: left;
                width: max-content;
                margin: 10px auto;
            }
        }
    }
   
}
.switchButtons{
    text-align: right;
    width: 100%;
}

.statisticTableMonths{
    :global(.ant-table-align-left.ant-table-row-cell-break-word){
        min-width: 100px;
    }
    :global(.ant-table-thead > tr > th .ant-table-header-column .ant-table-column-sorters > *:not(.ant-table-column-sorter)){
        width: min-content;
        min-width: 60px;
    }
    :global(.ant-table table){
        width: fit-content;
    }
}
.statisticTableDays{
    :global(.ant-table-align-center), 
    :global(.ant-table-row.ant-table-row-level-0 td){
        width: min-content;
        width: 110px;
    }
    :global(.ant-table-thead tr th:nth-child(1)), 
    :global(.ant-table-fixed tr td:nth-child(1)){
        width: 150px;
    }
    :global(::-webkit-scrollbar) {
        height: 10px;
      }
}
.statisticTableYearly{
    :global(.ant-table table){
        width: fit-content;
    }
}
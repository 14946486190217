@import "../../../common/globalStyles/colors.scss";

.editWindowWrap, .subSubcategory{
  padding: 6px;
  margin: 10px;
	// background-color: $darkgray-two;
	box-shadow: rgba(0, 0, 0, 0.2) 2px 2px 9px;
}

.subSubcategory {
	box-shadow: none;
}

.subcategoryTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.subCategory {
  padding: 0 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px 0 5px 8px;
  height: 30px;
  color: $white;
  background-color: $darkslategray;
	border-radius: 2px;
}

.subCategoryTitle {
  padding-right: 20px;
}

.subSubCategoryTitle {
  padding: 5px 10px 5px 5px;
  margin: 0;
  background-color: $darkslategray;
  color: $white;
  border-radius: 2px; 
}

.subSubCategoryIcon{
	margin: 0 4px;
}

.checkDish, .checkDishTitle {
  margin: 5px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px;
	background-color: $darkgray-two;
	box-shadow: rgba(0, 0, 0, 0.2) 1px 1px 5px;
}

.checkDishTitle {
	box-shadow: none
}

.checkTitle {
  color: $white;
}

.checkDishTitle {
  display: flex;
  justify-content: space-between;
  border: none;
}

.dishButton {
  margin-left: 10px;
}

.countDish {
  min-width: 70px;
}

.countGroupDish {
  margin-top: 5px;
}
@import "../../globalStyles/colors.scss";

.breadcrumbs {
	display: block;
	margin-bottom: 7px;
	min-width: 600px;
  ul{
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &One{
    border: 1px solid $darkslategray;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.6) 0px 2px 4px;
    display: inline-block;

    li{
      float: left;

      &:first-child a{
        padding-left: 1em;
      }
    }

    a{
      padding: 5px 7px 5px 20px;
      float: left;
      color: $white;
      font-weight: 600;
      position: relative;
      background-color: $darkslategray;
      background-image: linear-gradient(to right, lighten($darkslategray, 5%) , $darkslategray);

      &:hover{
        background: lighten($darkslategray, 5%);
      }

      &:after, &:before{
        content: "";
        position: absolute;
        top: 50%;
        margin-top: -1.2em;
        border-top: 1.1em solid transparent;
        border-bottom: 1.25em solid transparent;
        border-left: 1.2em solid;
        right: -1em;

      }

      &:after{
        z-index: 2;
        border-left-color: $darkslategray;
      }

      &:before{
        border-left-color: $darkslategray;
        right: -1.2em;
        z-index: 1;
      }

      &:hover::after{
        border-left-color: lighten($darkslategray, 5%);
      }
    }

  }
}

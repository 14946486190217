.wrap {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.wrapBottomButtons {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fieldsWrap {
	width: 60%;
}

.saveButton {
	margin: 20px 10px 20px 5px;
}
.select {
    width: 60%;
}
.selectShort {
    width: 30%;
}

.optionName {
    overflow: hidden;
    text-overflow: ellipsis;
}

@import "../../globalStyles/colors";

.inputWrapper {
  margin-bottom: 5px;
}

.errorMassage {
  margin-bottom: 5px;
}

.asterisk {
  color: $red;
}

@import "../../../common/globalStyles/colors.scss";

.popover {
  width: 80%
}

.popverButton {
  margin: 0 5px 0 0;
  width: 95%;
  font-size: 10px;
	height: 38px;
		&:last-child {
			margin: 0;
		}
}
.pageWrapper {
  height: calc(100vh - 300px);
}

.title {
  font-size: 200px;
  line-height: 200px;
}

.subTitle {
  text-align: center;
  font-size: 20px;
  margin-bottom: 50px;
}

.buttonWrapper {
  margin: 0 auto;
  max-width: 300px;
  width: 100%;
}

.buttonBack {
  width: 300px;
  font-size: 18px;
  line-height: 0px;
}

.popover {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 178px;
}

.popoverContent {
	margin: 0 auto;
	width: 96%;
}
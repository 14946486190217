.button {
	width: 100%;
  margin: 5px 0;
}

.form, .formOnlyFrom {
	margin-top: 25px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.formOnlyFrom {
	justify-content: center;
}

.datePicker {
	width: 59%;
}
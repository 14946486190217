.wrap {
  padding: 10px;
  min-height: 67vh;
}

.languageWrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


.spinnerWrap {
	padding-top: 5px;
	display: flex;
  justify-content: center;
  align-items: center;
}

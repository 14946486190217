@import "../../globalStyles/colors";

.materialShadow {
  padding: 10px;
  border: 1px solid $dark-one;
  background-color: $white;
  border-radius: 5px;
  box-shadow: $boxShadow;
  margin-bottom: 20px;
}
